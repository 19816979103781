import { Component } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent {
  Menuoption = "center";

  generalQuestion = [
    {
      question: '1) Tell me something about your company history?',
      answer: `
        <p class="mb-1">A. KD Elite stands for extremes in Health, Happiness and Spirituality. Here are some key points:</p>
        <ul>
          <li>Founded in 2005 with a mission to improve well-being.</li>
          <li>Global presence in over 50 countries.</li>
          <li>Focus on innovation and customer satisfaction.</li>
        </ul>

         <p class="mb-1">B. KD Elite stands for extremes in Health, Happiness and Spirituality. Here are some key points:</p>
        <ul>
          <li>Founded in 2005 with a mission to improve well-being.</li>
          <li>Global presence in over 50 countries.</li>
          <li>Focus on innovation and customer satisfaction.</li>
        </ul>
      `,
      
    },
    {
      question: '2) What are the main services your company provides?',
      answer: `Our vision is to improve the quality of our products...`
    },
    {
      question: '3) What are your payment terms?',
      answer: `A. All payments are accepted in US dollars...`
    }
  ];

  paymentQuestion = [
    {
      question: '1) Tell me something about Payment system',
      answer: `A. KD Elite stands for extremes in Health, Happiness and Spirituality...`
    },
    {
      question: '2) What are the main services your company provides?',
      answer: `Our vision is to improve the quality of our products...`
    },
    {
      question: '3) What are your payment terms?',
      answer: `A. All payments are accepted in US dollars...`
    }
  ];


  openIndex: number | null = null;

  toggle(index: number) {
    this.openIndex = this.openIndex === index ? null : index;
  }

  isOpen(index: number) {
    return this.openIndex === index;
  }

  // For Payment Questions
  openPaymentIndex: number | null = null;

  togglePayment(index: number) {
    this.openPaymentIndex = this.openPaymentIndex === index ? null : index;
  }

  isPaymentOpen(index: number) {
    return this.openPaymentIndex === index;
  }

}
