import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ImagePreviewService } from '../services/preview-image/preview-image.service';

@Component({
  selector: 'app-preview-image',
  templateUrl: './preview-image.component.html',
  styleUrls: ['./preview-image.component.css']
})
export class PreviewimageComponent {

  selectedImages: string[] = [];
  currentIndex: number = 0;
  showPreview: boolean;

  constructor(  
      private imagePreviewService: ImagePreviewService,
      private route:Router
  ) {}

  ngOnInit(): void {
    let images = JSON.parse(localStorage.getItem('work') || '[]');
         this.selectedImages = images;

  }

}
