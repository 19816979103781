<section class="section pt-5 mt-4 bg-dark" id="home">
  <div class="faq-section">
    <div class="container p-4 mt-100 mt-40">

      
            <h2 style="color: white" class="d-flex text-center justify-content-center">FAQs</h2>
                <h6 class="d-flex justify-content-center" style="color: #ffc01d">
                  <span style="color: white; margin-right: 4px">Got a question</span>We have answers.
                </h6>

            <div class="section-title mt-5 pb-3" id="payment">
              <h4 style="color: #adb5bd">General Questions</h4>
            </div>

            <div class="accordion bg-dark" id="faqAccordion">
                <div class="accordion-item" *ngFor="let faq of generalQuestion; let i = index">
                  <h2 class="accordion-header" id="faqAccordion"  [id]="'headingGeneral' + i">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      (click)="toggle(i)"
                      [attr.aria-expanded]="isOpen(i)"
                      [attr.aria-controls]="'collapseGeneral' + i"
                    >
                      {{ faq.question }}
                    </button>
                  </h2>
                  <div
                    [id]="'collapseGeneral' + i"
                    class="accordion-collapse collapse"
                    [ngClass]="{'show': isOpen(i)}"
                    [attr.aria-labelledby]="'headingGeneral' + i"
                    data-bs-parent="#faqAccordion"
                  >
                  <div class="line-separator"></div>

                    <div class="accordion-body"  [innerHTML]="faq.answer">
                    </div>
                  </div>
                </div>
            </div>

            <div class="section-title mt-5 pb-3" id="payment">
              <h4 style="color: #adb5bd">Payments Questions</h4>
            </div>

            <div class="accordion bg-dark" id="faqAccordion">
                  <div class="accordion-item" *ngFor="let faq1 of paymentQuestion; let j = index">
                    <h2 class="accordion-header" [id]="'headingPayment' + j">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        (click)="togglePayment(j)"
                        [attr.aria-expanded]="isPaymentOpen(j)"
                        [attr.aria-controls]="'collapsePayment' + j"
                      >
                        {{ faq1.question }}
                      </button>
                    </h2>
                    <div
                      [id]="'collapsePayment' + j"
                      class="accordion-collapse collapse"
                      [ngClass]="{'show': isPaymentOpen(j)}"
                      [attr.aria-labelledby]="'headingPayment' + j"
                      data-bs-parent="#paymentAccordion"
                    >
                    <div class="line-separator"></div> 

                      <div class="accordion-body"  [innerHTML]="faq1.answer"> 
                      </div>
                    </div>
                  </div>
            </div>
    </div>
  </div>
</section>
