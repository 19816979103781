import { Component, OnInit, Input, TemplateRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ImagePreviewService } from "../services/preview-image/preview-image.service";
import { OwlOptions } from "ngx-owl-carousel-o";
@Component({
  selector: 'app-Portfolio',
  templateUrl: './Portfolio.component.html',
  styleUrls: ['./Portfolio.component.css'],
})
export class PortfolioComponent implements OnInit{
  Menuoption = "center";
  selectedTemplates = [];
  currentSectionTitle: string = 'All Templates'; 
  showPreview = false;
  currentImageIndex = 0;

      eCommerce = [
        { name: 'E-Commerce', image: '../../../assets/images/1.jpg', disabled: false },
        { name: 'E-Commerce', image: '../../../assets/images/2.jpg', disabled: false },
        { name: 'E-Commerce', image: '../../../assets/images/3.jpg', disabled: false },
        { name: 'E-Commerce', image: '../../../assets/images/1.jpg', disabled: false },
        { name: 'E-Commerce', image: '../../../assets/images/2.jpg', disabled: false },
        { name: 'E-Commerce', image: '../../../assets/images/3.jpg', disabled: false },

      ];
    
      importExport = [
        { name: 'Import-Export', image: '../../../assets/images/2.jpg', disabled: false },
        { name: 'Import-Export', image: '../../../assets/images/2.jpg', disabled: false },
        { name: 'Import-Export', image: '../../../assets/images/2.jpg', disabled: false },
        { name: 'Import-Export', image: '../../../assets/images/2.jpg', disabled: false },
        { name: 'Import-Export', image: '../../../assets/images/2.jpg', disabled: false },

      ];
    
      companyJobWorker = [
        { name: 'Company Job Worker', image: '../../../assets/images/3.jpg', disabled: false },
        { name: 'Company Job Worker', image: '../../../assets/images/3.jpg', disabled: false },
        { name: 'Company Job Worker', image: '../../../assets/images/3.jpg', disabled: false },
        { name: 'Company Job Worker', image: '../../../assets/images/3.jpg', disabled: false },
        { name: 'Company Job Worker', image: '../../../assets/images/3.jpg', disabled: false },

      ];
    
      deliveryLogistic = [
        { name: 'Delivery & Logistic', image: '../../../assets/images/1.jpg', disabled: false },
        { name: 'Delivery & Logistic', image: '../../../assets/images/1.jpg', disabled: false },
        { name: 'Delivery & Logistic', image: '../../../assets/images/1.jpg', disabled: false },
        { name: 'Delivery & Logistic', image: '../../../assets/images/1.jpg', disabled: false },
        { name: 'Delivery & Logistic', image: '../../../assets/images/1.jpg', disabled: false },

      ];
    
      medicalHealthcare = [
        { name: 'Medical & Healthcare ', image: '../../../assets/images/2.jpg', disabled: false },
        { name: 'Medical & Healthcare', image: '../../../assets/images/2.jpg', disabled: false },
        { name: 'Medical & Healthcare', image: '../../../assets/images/2.jpg', disabled: false },
        { name: 'Medical & Healthcare', image: '../../../assets/images/2.jpg', disabled: false },
        { name: 'Medical & Healthcare', image: '../../../assets/images/2.jpg', disabled: false },

      ];
    
      furniture = [
        { name: 'Furniture', image: '../../../assets/images/3.jpg', disabled: false },
        { name: 'Furniture', image: '../../../assets/images/3.jpg', disabled: false },
        { name: 'Furniture', image: '../../../assets/images/3.jpg', disabled: false },
        { name: 'Furniture', image: '../../../assets/images/3.jpg', disabled: false },
        { name: 'Furniture', image: '../../../assets/images/3.jpg', disabled: false },

      ];
      
  
    
  constructor(private route:ActivatedRoute,
    private imagePreviewService: ImagePreviewService
  ) {}
    

  ngOnInit(): void {
    this.route.url.subscribe(url => {
      const path = url[1].path || 'all';
      this.loadTemplates(path);
    });
}


loadAllTemplates(): void {
  this.selectedTemplates = [
    ...this.eCommerce,
    ...this.importExport,
    ...this.companyJobWorker,
    ...this.deliveryLogistic,
    ...this.medicalHealthcare,
    ...this.furniture,
  ];
  this.currentSectionTitle = 'All Templates'; 

}

loadTemplates(path: string): void {
  switch (path) {
    case 'e-commerce':
      this.selectedTemplates = this.eCommerce;
      this.currentSectionTitle = 'E-Commerce'; 
      break;
    case 'import-export':
      this.selectedTemplates = this.importExport;
      this.currentSectionTitle = 'Import-Export'; 
      break;
    case 'company-job-worker':
      this.selectedTemplates = this.companyJobWorker;
      this.currentSectionTitle = 'Company Job Worker'; 
      break;
    case 'delivery-logistic':
      this.selectedTemplates = this.deliveryLogistic;
      this.currentSectionTitle = 'Delivery & Logistic'; 
      break;
    case 'medical-healthcare':
      this.selectedTemplates = this.medicalHealthcare;
      this.currentSectionTitle = 'Medical & Healthcare'; 
      break;
    case 'furniture':
      this.selectedTemplates = this.furniture;
      this.currentSectionTitle = 'Furniture'; 
      break;
    default:
      this.loadAllTemplates(); 
  }
}


openPreview(): void {
  const images = this.selectedTemplates.map(template => template.image);
  localStorage.setItem('work', JSON.stringify(images));
  window.open('/preview-image', '_blank');
}


closePreview(): void {
  this.showPreview = false;
}

nextImage(): void {
  if (this.currentImageIndex < this.selectedTemplates.length - 1) {
    this.currentImageIndex++;
  }
}

prevImage(): void {
  if (this.currentImageIndex > 0) {
    this.currentImageIndex--;
  }
}
}