<section class="section pt-5 mt-4 bg-dark" id="home">
  <h5 class="d-flex justify-content-center title" style="color:white">{{ currentSectionTitle }}</h5> 
  <div class="template-grid container mt-100 mt-40 bg-dark">
    <div class="template-item" *ngFor="let item of selectedTemplates; let i = index">
      <div class="template-image bg-dark">
        <img [src]="item.image" alt="{{ item.name }}">
      </div>
      <div class="template-details">
        <h6 style="color:white">{{ item.name }}</h6>
        <div class="button-group">
          <button class="btn btn-preview mt-3" (click)="openPreview(i)">Preview</button>
        </div>
      </div>
    </div>
  </div>
</section>
