<!-- Start Contact -->
<section class="section pt-5 mt-4 bg-dark" id="home">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 p-0">
        <div class="card map border-0">
          <div class="card-body p-0">
            <!-- <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
              style="border:0" allowfullscreen></iframe> -->
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-40">
    <div class="row align-items-center">
      <div
        class="col-lg-5 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1"
      >
        <div class="card custom-form rounded border-0 shadow p-4">
          <form [formGroup]="contactForm">
            <p id="error-msg" class="mb-0"></p>
            <div id="simple-msg"></div>
            <div class="row">
              <div class="col-md-12">
                <div class="mb-3">
                  <label for="name" class="form-label text-white"
                    >Your Name <span class="text-danger">*</span></label
                  >
                  <div class="form-icon position-relative">
                    <i-feather
                      name="user"
                      class="fea icon-sm icons"
                      style="color: white"
                    ></i-feather>
                    <input
                      name="name"
                      id="name"
                      type="text"
                      formControlName="fullName"
                      class="form-control ps-5"
                      placeholder="Name :"
                      autocomplete="off"
                      style="background: #2b3949;
                      border: 1.8px solid white;
                      color: white !important;"
                      [ngClass]="{
                        'is-invalid': submitted && f.fullName.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && f.fullName.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="f.fullName.errors.required">
                        Name is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="mb-3">
                  <label for="email" class="form-label text-white"
                    >Your Email <span class="text-danger">*</span></label
                  >
                  <div class="form-icon position-relative">
                    <i-feather
                      name="mail"
                      class="fea icon-sm icons"
                      style="color: white"
                    ></i-feather>
                    <input
                      name="email"
                      id="email"
                      formControlName="email"
                      type="email"
                      class="form-control ps-5"
                      autocomplete="off"
                      placeholder="Email :"
                      style="background: #2b3949;
                      border: 1.8px solid white;
                      color: white !important;"
                      [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                    />
                    <div
                      *ngIf="submitted && f.email.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="f.email.errors.required">
                        Email is required
                      </div>
                      <div *ngIf="f.email.errors.email">
                        Email must be a valid email address
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end col-->
              <div class="col-md-12">
                <div class="mb-3">
                  <label for="phone" class="form-label text-white"
                    >Your Phone <span class="text-danger">*</span></label
                  >
                  <div class="form-icon position-relative">
                    <i-feather
                      name="phone"
                      class="fea icon-sm icons"
                      style="color: white"
                    ></i-feather>
                    <input
                      name="phone"
                      id="phone"
                      formControlName="phone"
                      type="tel"
                      class="form-control ps-5"
                      autocomplete="off"
                      placeholder="Phone :"
                      style="background: #2b3949;
                      border: 1.8px solid white;
                      color: white !important;"
                      [ngClass]="{
                        'is-invalid': submitted && f.phone.errors
                      }"
                    />
                    <div
                      *ngIf="submitted && f.phone.errors"
                      class="invalid-feedback"
                    >
                      <div *ngIf="f.phone.errors.required">
                        Phone is required
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="mb-3">
                  <label for="subject" class="form-label text-white">Subject</label>
                  <div class="form-icon position-relative">
                    <i-feather
                      name="book"
                      class="fea icon-sm icons"
                      style="color: white"
                    ></i-feather>
                    <input
                      name="subject"
                      formControlName="subject"
                      id="subject"
                      class="form-control ps-5"
                      autocomplete="off"
                      placeholder="subject :"
                      style="background: #2b3949;
                      border: 1.8px solid white;
                      color: white !important;"
                    />
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-12">
                <div class="mb-3">
                  <label for="comments" class="form-label text-white"
                    >Comments</label
                  >
                  <div class="form-icon position-relative">
                    <i-feather
                      name="message-circle"
                      style="color: white"
                      class="fea icon-sm icons clearfix"
                    ></i-feather>
                    <textarea
                      name="comments"
                      id="comments"
                      autocomplete="off"
                      formControlName="comments"
                      rows="4"
                      class="form-control ps-5"
                      placeholder="Message :"
                      style="background: #2b3949;
                      border: 1.8px solid white;
                      color: white !important;"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="d-grid">
                  <button
                    type="submit"
                    class="btn btn-primary"
                    (click)="submit()"
                  >
                    Send Message
                  </button>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </form>
        </div>
        <!--end custom-form-->
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 order-1 order-md-2">
        <div class="title-heading ms-lg-4">
          <h4 class="mb-4 text-white">Contact Us</h4>
          <p class="text-muted">
            We delivered more than 16 projects globally with our 15+ talented
            engineers and 3+ years of expertise, and 18+ clients hired committed
            personnel from us. Get in touch with us right away, and we'll help
            you create the next generation of software solutions for your
            company's requirements.
          </p>
          <h4 class="mb-4 text-white">Contact Details</h4>
          <p class="text-muted">
            <span class="text-primary fw-bold"></span> We’re here to help! Tell
            us what you’re looking for and we’ll get you connected to the right
            people.
          </p>
          <div class="d-flex contact-detail align-items-center mt-3">
            <div class="icon">
              <i-feather
                name="mail"
                class="fea icon-m-md text-dark me-3"
                style="color: #ffc01d !important"
              ></i-feather>
            </div>
            <div class="flex-1 content">
              <h6 class="title fw-bold mb-0 text-white">Email</h6>
              <a href="mailto:contact@tenzed.com" class="text-primary"
                ><div [innerHTML]="'contact@tenzed.com'"></div> </a
              >
            </div>
          </div>

          <div class="d-flex contact-detail align-items-center mt-3">
            <div class="icon">
              <i-feather
                name="phone"
                class="fea icon-m-md text-dark me-3"
                style="color: #ffc01d !important"
              ></i-feather>
            </div>
            <div class="flex-1 content">
              <h6 class="title fw-bold mb-0 text-white">Phone</h6>
              <a href="tel:+919499851305" class="text-primary"
                >+91 94998 51305</a
              >
            </div>
          </div>

          <div class="d-flex contact-detail align-items-center mt-3">
            <div class="icon">
              <i-feather
                name="map-pin"
                class="fea icon-m-md text-dark me-3"
                style="color: #ffc01d !important"
              ></i-feather>
            </div>
            <div class="flex-1 content">
              <h6 class="title fw-bold mb-0 text-white">Location</h6>

              <a
                (click)="mapView(content)"
                data-type="iframe"
                class="video-play-icon text-primary"
                >View on Google map</a
              >
              <ng-template #content let-modal>
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    aria-label="Close"
                    (click)="modal.dismiss('Cross click')"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <!-- <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                    height="450" width="750">
                  </iframe> -->
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End contact -->

<!-- Back to top -->
<a
  href="javascript: void(0);"
  class="btn btn-icon btn-primary back-to-top"
  id="back-to-top"
  style="background: #ffc01d !important; border-color: #ffc01d !important"
  [ngxScrollTo]="'#home'"
>
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
